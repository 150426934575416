"use client";

import { useRef } from "react";

import { authStoreContext, createAuthStore } from "./authStore";

import type { ReactNode } from "react";
import type { AuthStoreApi } from "./authStore";

export function AuthStoreProvider({ children }: { children: ReactNode }) {
  const storeRef = useRef<AuthStoreApi>();

  if (!storeRef.current) {
    storeRef.current = createAuthStore();
  }

  return <authStoreContext.Provider value={storeRef.current}>{children}</authStoreContext.Provider>;
}
