import { createContext, useContext } from "react";
import { createStore, useStore } from "zustand";

import { endpoints, sendRequest } from "@skydio/api_util/src/backends/cloud_api";
import { logoutRemoveCookies } from "@skydio/api_util/src/backends/cloud_api/requests-browser";

import type { APIUser } from "@skydio/api_util/src/backends/cloud_api";

export type AuthState = {
  authenticated: boolean | null;
  user: APIUser | null;
};

export type AuthActions = {
  setCurrentUser: (user: APIUser) => void;
  checkAuth: () => Promise<void>;
  resetAuth: () => Promise<void>;
  logOut: () => Promise<void>;
};

export type AuthStore = AuthState & AuthActions;

const defaultAuthState = {
  authenticated: null,
  user: null,
};

export const createAuthStore = () =>
  createStore<AuthStore>()((set, get) => ({
    ...defaultAuthState,
    setCurrentUser: (user: APIUser) => set({ user, authenticated: true }),
    checkAuth: async () => {
      try {
        const response = await sendRequest(endpoints.GET_CURRENT_USER);
        set({ user: response.toObject(), authenticated: true });
      } catch {
        set({ user: null, authenticated: false });
      }
    },
    resetAuth: async () => {
      if (get().authenticated) {
        await get().checkAuth();
      }
    },
    logOut: async () => {
      await sendRequest(endpoints.LOGOUT);
      logoutRemoveCookies();
      set({ user: null, authenticated: false });
    },
  }));

export type AuthStoreApi = ReturnType<typeof createAuthStore>;

export const authStoreContext = createContext<AuthStoreApi | undefined>(undefined);

export const useAuthStore = <T>(selector: (store: AuthStore) => T): T => {
  const context = useContext(authStoreContext);

  if (!context) {
    throw new Error("useAuthStore must be used within an AuthStoreProvider");
  }

  return useStore(context, selector);
};
